.container {
  padding: 1rem;
  text-align: center;
  direction: rtl;
}

.header {
  font-size: 32px;
}

.button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

ul {
  text-align: right;
}

li {
  padding-top: 0.7rem;
}

.resultsTable {
  border-collapse: collapse;
  font-size: 0.9rem;
  direction: ltr;
  text-align :left;
  line-height: 2rem;
  margin: auto;
}

.resultsTable thead tr {
  background-color: darkgray;
  text-align: left;
}

.resultsTable th,
.resultsTable td {
  padding: 0.5rem 0.2rem;
  border: 1px solid #dddddd;
}

.resultsTable th {
  padding: 0.75rem 0;
}

.resultsTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.resultsTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}