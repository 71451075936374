.videoTitle {
  color: white;
  position: relative;
  z-index: 1;
  direction: ltr;
}

.videoPlayerContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  align-items: center;
  justify-content: center;
}
